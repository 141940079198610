export default {
	legacy: false,
	locale: "en",
	messages: {
		en: {
			//TODO: Maintain Alphabetical order!!!
			accept: "Accept",
			addedToCart: "Added",
			addOn: "Add-On",
			addOns: "Add-Ons",
			addToCart: "Add To Cart",
			apply: "Apply",
			back: "Back",
			backToBeginning: "Back to Beginning",
			billingFirstName: "Billing First Name",
			billingInformationSubtitle: "Enter the billing information associated with the card below.",
			billingInformationTitle: "Billing Information",
			billingLastName: "Billing Last Name",
			buyNow: "Buy Now",
			cardCVVCode: "CVV Code",
			cardCvvPlaceholder: "CVV",
			cardExpirationDate: "Expiration Date",
			cardExpiryDatePlaceholder: "MM / YY",
			cardNumber: "Card Number",
			cartTimeoutBody: "Your cart has expired and your cart items were removed from your cart. We hope to see you on the Dancefloor!",
			cartTimeoutCountdown: "Your items will be held for {time}",
			cartTimeoutTitle: "Uh Oh, Your Cart Expired!",
			cartTimeoutWarningBody: "Your cart is about the expire and your cart items will no longer be held. Would you like {minutes} more minutes?",
			cartTimeoutWarningTitle: "Warning!",
			checkout: "Checkout",
			checkoutBillingTo: "Billing To:",
			checkoutOrderConfirmation: "Order Confirmation",
			checkoutOrderContact: "Order Contact:",
			checkoutOrderDate: "Order Date:",
			checkoutOrderNumber: "Order Number:",
			checkoutPaymentType: "Payment Type:",
			checkoutPageError: "Some of your payment information is incorrect or missing. Please double check your data and resubmit your order. If the problem persists, try a different browser or call our support team.",
			checkoutPrice: "Price",
			checkoutProduct: "Product",
			checkoutQuantity: "Quantity",
			checkoutQuantityAbbreviated: "Qty",
			checkoutShippingTo: "Shipping To:",
			clearCart: "Clear Cart",
			close: "Close",
			confirmation: "Confirm",
			contactAndShippingSubtitle: "Please provide contact and shipping details for this order.",
			contactAndShippingTitle: "Contact & Shipping Information",
			continue: "Continue",
			cookiesNotice: "Our site uses analytical tools to help make your experience better. We’d love to have you" +
				" accept our cookies but we’ll always respect your data choices. Some cookies are necessary for our page to work.",
			couponFieldLabel: "Apply Code",
			couponFieldPlaceholder: "Enter Code",
			couponFieldTooltip: "Add at least one item to your cart. Then fill out the Shipping First Name, Shipping Last Name and Shipping Email to apply a code to your order.",
			decline: "Decline",
			disabledCheckout: "We have disabled the checkout functionality. You can still browse the site and build a cart.",
			discounts: "Discount(s)",
			discoverMore: "Discover More",
			donation: "Donation",
			donations: "Donations",
			dropdownPlaceholder: "Select",
			edit: "edit",
			ellipsis: "...",
			emptyCart: "You have no items in your cart",
			errorPageBody: "Sorry about that, we are working to fix the issue.",
			errorPageMissingBody: "The page you are looking for does not exist",
			errorPageMissingTitle: "Not Found",
			errorPageTitle: "Oops! Something went wrong!",
			errorPopupDefaultBody: 'Error!',
			errorPopupDefaultTitle: 'Error!',
			errorPopupRefresh: "Sorry about that, we are working to fix the issue. Please refresh your browser.",
			fetchingProducts: "Fetching Products...",
			firstName: "First Name",
			gst: "GST",
			hideDetails: "Hide Details",
			homepageBody_addOns: 'Take a look at some of our Add-Ons. If none of them tickle your fancy, click "Next" to skip.\n\n',
			homepageBody_checkout: "We can't wait to see you on the dancefloor!\n\n",
			homepageBody_confirmation: "A confirmation email has been sent to {email}.\nWe will send you an additional email once your order has been processed.",
			homepageBody_donations: 'Please consider donating to some of our amazing partners.',
			homepageBody_lodging: 'Select your desired lodging experience. If you already have a place to \nstay, use the icons above to navigate or click "Next" to skip.',
			homepageBody_merch: 'Discover exclusive accessories to complement your purchase and maximize its potential.\n\n',
			homepageBody_shuttles: "Don't want to drive? Hitch a ride on one of our shuttles.",
			homepageBody_tickets: 'Select and add tickets to your cart. If you already have tickets, right on!\nUse the icons above to navigate or click "Next" to skip.',
			homepageHeading_addOns: "Interested in some upgrades?",
			homepageHeading_checkout: "All set? Let's get you checked out.",
			homepageHeading_confirmation: "Thanks for your order {name}!",
			homepageHeading_donations: "Donations",
			homepageHeading_lodging: "Choose your Lodging Package!",
			homepageHeading_merch: "Pick up some Merch",
			homepageHeading_shuttles: "Need a ride?",
			homepageHeading_tickets: "Let's get you some tickets!",
			installmentsNote: "Note: The following items are not eligible for payment plans. These items will be included and paid in full with your first payment.",
			installmentsNotEligible: "None of your items are eligible",
			installmentsOf: "Payment 1 of {numInstallments}",
			invalidAddress1: "Please enter a valid address",
			invalidCardNumber: "Please enter a valid credit card number",
			invalidCity: "Please enter a valid city",
			invalidCountry: "Please enter a valid country",
			invalidCVV: "Please enter a valid cvv",
			invalidEmail: "Please double check the email(s) entered",
			invalidExpiryDate: "Please enter a valid expiry date",
			invalidFirstName: "Please enter a valid first name",
			invalidLastName: "Please enter a valid last name",
			invalidPhone: "Please enter a valid phone number",
			invalidPostalCode: "Please enter a valid Postal Code",
			invalidProvince: "Please enter a valid Province",
			invalidState: "Please enter a valid State",
			invalidZipcode: "Please enter a valid Zip Code",
			joinWaitlist: "Join Waitlist",
			leaveSiteBtn: "Back to Site",
			lodging: "Lodging",
			loginPageBody: "The site is only available to system administrators right now.",
			loginPageTitle: "Welcome to the {eventName} Ticket Portal",
			loremIpsumLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
			loremIpsumShort: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
			maintenance1: "Sorry, we're down for scheduled maintenance right now.",
			maintenance2: "We can't wait to welcome you back at our next event.",
			maintenanceBtn: "Continue Wandering",
			maxReachedBody: "You may not exceed a maximum of: {max} units per order for this product.",
			maxReachedTitle: "Maximum Limit Reached",
			merch: "Merch",
			miniCartCoupons: "Apply Codes during Checkout.",
			miniCartSubtitle: "Here's what we currently have in your cart.",
			miniCartTitle: "Cart Summary",
			newsAndUpdates: "I would like to receive news and updates from {eventName}",
			next: "Next",
			no: "No",
			noCartExists: "Empty Cart",
			noCartItemsBody: "Please add something to your cart before performing this action. If you are experiencing problems, please contact our support team.",
			noCartItemsForOrder: "Please add something to your cart before placing an order",
			noCartItemsTitle: "No Cart Items",
			noInternet: "You're Offline",
			noInternetBody: "You have no internet connection right now. Please find a stronger internet connection or try refreshing the page.",
			noLodgingItem: "Please add a lodging item",
			nonRefundable: "Non-Refundable",
			noRefundableItems: "None of your items are refundable",
			noTicketsPopupBody: "You need a ticket to attend, are you sure you wish to continue without one?",
			noTicketsPopupTitle: "You have no tickets",
			ok: "ok",
			orderPlacedPopupBody: "You will receive a confirmation email shortly.\nWe will send you an additional email once your order has been processed.",
			orderPlacedPopupTitle: "Thanks for your order!",
			orderSummaryPrices: "All prices shown below are in {currencyName}.",
			orderSummarySubtitle: "Here's what we currently have in your cart.",
			orderSummaryTitle: "Order Summary",
			paymentDue: "Payment Due: {date}",
			paymentInstalments: "Payments",
			paymentInstalmentsSubtitle: "Pay for your order with monthly payments.\nPayments are non-refundable.",
			paymentMethodSubtitle: "Enter the credit card details for this order.",
			paymentMethodTitle: "Payment Method",
			person: "Person",
			phoneTooltip: "Type an alternative phone number by entering a plus symbol (+) and your country code, the flag icon will indicate if you have correctly entered your number.",
			placeOrder: "Submit",
			plusTax: "Plus Tax",
			poorConnectionBody: "You've been lost in the crowd. Please try to find a stronger internet connection.",
			poorConnectionTitle: "Poor Connection Detected",
			proceed: "Proceed",
			processing: "Fees",
			productImagesTitle: "Gallery",
			pst: "PST",
			recommended: "(Recommended)",
			refresh: "Refresh",
			refundable: "Refundable",
			refundableBooking: "Refundable Booking",
			refundableBookingCardTitle: "Refundable Booking",
			refundableBookingCardSubtitle: "Be prepared for the unexpected.",
			refundableInfoBody1: "Select Refundable Booking and receive a refund on select items if you are unable to attend the event due to any of the listed reasons below. Required evidence must be shown. Subject always to the General Conditions of Refund.",
			refundableInfoMore1: "For more information, see",
			refundableInfoMore2: "Terms and Conditions",
			refundableInfoMore3: "or",
			refundableInfoMore4: "FAQs",
			refundableInfoReason1a: "Covid-19 Infection,",
			refundableInfoReason1b: "See Details",
			refundableInfoReason2: "Sickness, Accident, Injury",
			refundableInfoReason3: "Public Transport Failure",
			refundableInfoReason4: "Private Vehicle Failure",
			refundableInfoReason5: "Emergency Services Recall",
			refundableInfoReason6: "Home Emergency",
			refundableInfoReason7: "Immediate Relative Sickness",
			refundableInfoReason8: "Pre-existing Medical Condition",
			refundableInfoReason9: "And Many More...",
			refundableInfoTitle: "Refundable Booking Details",
			removeItem: "Remove Item",
			reserved: "Reserved",
			reservedNow: "Reserve Now",
			sameBillingAndShipping: "Billing and Shipping Information are the same",
			search: "Search",
			seatsIoErrorPopupBody: "Your item is secure in your cart, and your spot is guaranteed! <br/><br/>However, we are unable to save your preferred location at the moment, but we are working to resolve this promptly. You may still proceed to checkout.",
			seatsIoErrorPopupTitle: "Oops!",
			seatsIoLabel: "[b]Site ID: [/b] {id}",
			seatsIoPopupAutoReserve: "Auto-book {qty} spot(s)",
			seatsIoPopupHelp: "Use our interactive map to select your reserved camping spot(s). Your selection will be held for you until you complete the checkout process. You can skip this step and we will select the best available spots for you.",
			seatsIoPopupList: "You are eligible to reserve",
			seatsIoPopupListRemove: "Please remove",
			seatsIoPopupTitle: "Reserve your Spot",
			seatsIoPopupTitleRemove: "Un-select a Spot",
			seatsIoPreviewHelp: "This interactive map shows the live status of our reserved camping areas. Sites on hold or already booked will appear as 'not available' in the map.\nTo reserve your spot, add an item to your cart and select your space.",
			seatsIoPreviewLink: "Click here to view map",
			seatsIoPreviewTitle: "Sites / Tents Map",
			serverError: "Server Error",
			serverErrorUnhandled: "{msg}. Please contact customer service to resolve the issue.",
			shippingAddress1: "Address",
			shippingAddress2: "Address 2",
			shippingCity: "City",
			shippingCountry: "Country",
			shippingEmail: "Email",
			shippingEmailConfirm: "Confirm Email",
			shippingFirstName: "First Name",
			shippingLastName: "Last Name",
			shippingPhone: "Phone",
			shippingPostalCode: "Postal Code",
			shippingProvince: "Province",
			shippingState: "State",
			shippingZipcode: "Zip Code",
			shuttle: "Shuttle",
			shuttles: "Shuttles",
			siteNotificationMsg: "This is the site notification banner. Update this message for the client.",
			stagingSiteWarning: "This is our testing site. Orders placed on this site are NOT valid and will NOT be honored.",
			soldOut: "Sold Out",
			soldOutTooltip: "*Please note: when the button says ‘Unavailable’, all items are currently in carts. Please be patient as abandoned carts replenish inventory.",
			stay: "Stay",
			subtotal: "Subtotal",
			sunday: "Sunday Sales",
			sundays: "Sunday Sales",
			termsAndConditions: "Terms & Conditions",
			ticket: "Ticket",
			tickets: "Tickets",
			total: "Total",
			totalDue: "Total Due Today",
			totalRemaining: "Total Remaining",
			tryAgain: "Try Again!",
			unavailable: "Unavailable",
			viewDetails: "View Details",
			volunteer: "Volunteer Deposits",
			volunteers: "Volunteer Deposits",
			xMoreMinutes: "{minutes} More Minutes!",
			yes: "Yes",
		},
		fr: {},
	},
}
