//Note that special characters needs to be escaped: https://vue-i18n.intlify.dev/guide/essentials/syntax.html#special-characters
export default {
	messages: {
		en: {
			terms: "<h1 style=\"line-height:1.38;margin-bottom:6pt;margin-top:20pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:20pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Bass Coast Terms and Conditions&nbsp;</span></span></h1>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">General Admission Tickets are valid for entry July 11-14, 2025 (dates subject to change). Bass Coast Festival tickets are non-refundable. By purchasing a Bass Coast Festival ticket, you are agreeing to our terms and conditions. 19+ Event, ID required upon entry. No parking/camping is permitted on the road the night before entry.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">PLEASE READ CAREFULLY. BY PURCHASING OR USING THIS TICKET YOU ARE AGREEING TO THESE TERMS AND CONDITIONS.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Waiver of Liability (</span></span><a style=\"text-decoration:none;\" target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://basscoast.ca/pages/liability-waiver\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"-webkit-text-decoration-skip:none;font-style:normal;font-variant:normal;font-weight:400;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre-wrap;\"><u>https://basscoast.ca/pages/liability-waiver</u></span></span></a><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">)</span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">And</span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Code of Conduct (</span></span><a style=\"text-decoration:none;\" target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://basscoast.ca/pages/code-of-conduct\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"-webkit-text-decoration-skip:none;font-style:normal;font-variant:normal;font-weight:400;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre-wrap;\"><u>https://basscoast.ca/pages/code-of-conduct</u></span></span></a><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">)</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Tickets and Passes</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">All tickets and passes are non-refundable. All credentials are the sole property of Bass Coast Festival. Bass Coast will not be responsible for lost tickets or passes and does not issue replacement tickets or passes. Bass Coast will not issue refunds for any reason, including if the event is terminated early or cancelled due to extreme weather, acts of nature, acts of terrorism or government regulation. Bass Coast will not issue refunds for any failure or inability to use tickets due to illness, positive COVID-19 tests, acts of God, public health crisis, government restrictions, travel-related problems, or other conditions beyond the control of Bass Coast Festival. Please ensure that you will be able to attend the festival before purchasing your tickets. Your ticket is valid only for the year on your Bass Coast ticket. Bass Coast reserves the right, at its sole discretion, to refuse entry to anyone without refund of ticket or pass. All ticket holders must be 19 years of age and present two pieces of government-issued identification. Tickets and passes are a revocable license and may be revoked at any time, for any reason, without compensation. Any ticket that has been duplicated or tampered with is invalid.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Bass Coast reserves the right to change the programming without notification.&nbsp;</span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">All tickets include camping in the general camping area(s).&nbsp;</span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Re-entry will be charged at $20 per person. Local Merritt residents are exempt from this charge.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Prohibited Items</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">All vehicles will be searched upon entry. Guests and vehicles will be subject to searches prior to entry. Prohibited items include alcohol, drugs, glass, mirrors, weapons, flares, fireworks and pets (guide dogs are permitted with pre-registration). ). Full list of all prohibited items can be found in the FAQ section. Prohibited items are subject to seizure. The festival is not responsible for any confiscated items.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">General Info and Conditions</span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">More detailed information is available on the Bass Coast website FAQ page.</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Vehicles parked in marked fire lanes will be towed without notice to the owner.</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Potable drinking water is available free of charge at the Food Court and in the Forest.</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">No unauthorized vending is permitted.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">COVID-19 Information</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Bass Coast Festival will continue to operate in accordance with provincial and local health guidelines and recommendations.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Updates to Terms</span></span><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Bass Coast reserves the right to make changes to the Terms as posted.</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Your tickets are being purchased from Bass Coast Festival (the organizer).</span></span></p>\n" +
				"<h3 style=\"line-height:1.38;margin-bottom:4pt;margin-top:16pt;\" dir=\"ltr\">&nbsp;</h3>\n" +
				"<h3 style=\"line-height:1.38;margin-bottom:4pt;margin-top:16pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:13.999999999999998pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Data Sharing&nbsp;</span></span></h3>\n" +
				"<p>&nbsp;</p>" +
				"<p style=\"line-height:2.1;margin-bottom:15pt;margin-top:0pt;text-align:justify;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Data Collection Clause for Event Website:</strong></span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">The customer acknowledges that Bass Coast collects and uses data on platform usage to generate insights and improve the platform.&nbsp;</span></span></p>\n" +
				"<p style=\"line-height:1.38;margin-bottom:0pt;margin-top:0pt;\" dir=\"ltr\">&nbsp;</p>\n" +
				"<ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Types of Data Collected:</strong></span></span></li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\">&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;\">We may collect the following types of personal and non-personal information from users:</span></span>\n" +
				"        <ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"        <ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Personal Information: Name, email address, phone number, postal address, and any other information voluntarily provided by users during registration or ticket purchase.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Non-Personal Information: Browser information, device type, IP address, cookies, and usage data collected through analytics tools.</span></span></li>\n" +
				"        </ul>\n" +
				"    </li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\">&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Purpose of Data Collection:</strong></span></span><br>&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;\">We collect user data for the following purposes:</span></span>\n" +
				"        <ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Event Registration: To facilitate event registration and ticket purchase.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Communication: To send event updates, confirmations, and important information related to the event.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Analytics: To analyze user behavior on our website and improve our services.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Marketing: To send promotional materials or offers related to our events with user consent.</span></span></li>\n" +
				"        </ul>\n" +
				"    </li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\">&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Data Sharing:</strong></span></span><br>&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;\">We may share user data with the following third parties:</span></span>\n" +
				"        <ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Event Organizers: Information provided during event registration may be shared with the event organizers.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Service Providers: We may use third-party service providers for payment processing, analytics, and marketing. These providers may have access to user data as necessary for their services.</span></span></li>\n" +
				"        </ul>\n" +
				"    </li>\n" +
				"</ul>\n" +
				"<p style=\"line-height:1.3800000000000001;margin-bottom:15pt;margin-left:36pt;margin-top:15pt;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Bass Coast will not, and will not authorize any third party to: (a) rent, sublicense, transfer, disclose, use, or grant any rights in, or share or provide access to any Customer Data, in any form, collected and created under this Agreement; or (b) collect, use, combine, aggregate, or commingle the Customer Data for the benefit of any third party.&nbsp;</span></span></p>\n" +
				"<ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Data Security:</strong></span></span></li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">We take reasonable measures to protect user data. However, please be aware that no method of online transmission or storage is entirely secure.</span></span></li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\">&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>User Rights:</strong></span></span></li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;\" dir=\"ltr\" aria-level=\"1\"><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;\">Users have the following rights regarding their data:</span></span>\n" +
				"        <ul style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Access: Users can request access to their personal data we have collected.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Rectification: Users can request corrections to inaccurate data.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Deletion: Users can request the deletion of their data, subject to legal obligations.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:disc;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Opt-out: Users can opt-out of receiving marketing communications.</span></span>\n" +
				"            </li>\n" +
				"        </ul>\n" +
				"    </li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:700;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\">&nbsp;</li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Cookies and Tracking Technologies:</strong></span></span></li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><br><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">We use cookies and similar tracking technologies to enhance the user experience and gather information about user interactions with our website.</span></span></li>\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;font-weight:400;list-style-type:none;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"1\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">By using our website and providing your information, you agree to the terms outlined in this data collection clause and our privacy policy.</span></span></li>\n" +
				"</ul>\n" +
				"<h3 style=\"line-height:1.3800000000000001;margin-bottom:4pt;margin-top:16pt;text-align:justify;\" dir=\"ltr\">&nbsp;</h3>\n" +
				"<h3 style=\"line-height:1.3800000000000001;margin-bottom:4pt;margin-top:16pt;text-align:justify;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:13.999999999999998pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Payment Plans</span></span></h3>\n" +
				"<p>&nbsp;</p>\n" +
				"<p style=\"line-height:1.3800000000000001;margin-bottom:0pt;margin-top:0pt;text-align:justify;\" dir=\"ltr\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Payment Plan:&nbsp;</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Bass Coast Ltd. offers Event tickets, reserved sites, glamping, and&nbsp; add ons on an installment basis (the “Payment Plan”).&nbsp; The availability of ticket and add-on services purchased under the Payment Plan is limited and the following policies apply:</span></span></p>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Fees:&nbsp;</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;\">There is a one-time $6 fee to use the Payment Plan.</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"2\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Payment Plans Are Available Only on the Website:&nbsp;&nbsp;</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Participants seeking to engage in a Payment Plan must indicate so at the time of “checkout”.&nbsp;&nbsp;</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"3\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Payment Frequency:&nbsp;&nbsp;</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Participants may select the frequency of their payment installments.&nbsp; The payment amounts and dates will be displayed at checkout and will be sent out in the “order confirmation” email issued after ticket purchase by the Event organizers.&nbsp; The first installment must be paid immediately at the time of purchase. Guests can review their payment schedules via their User Portal accounts.</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"4\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Important Dates:</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong> </strong>All payment installments must be completed no later than July 4, 2025. Failure to do so will result in the cancellation of your ticket, with a credit applied to your account.&nbsp;</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"5\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Pre-Authorized Payments:</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong> </strong>By choosing the Payment Plan options, you hereby authorize Bass Coast Project Ltd. to collect monthly payments from your credit card as set out in the agreed-upon payment schedule.</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"6\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Providing Accurate Information:&nbsp;&nbsp;</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Any Participant who seeks to use the Payment Plan must:</span></span>\n" +
				"        <ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\">\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-roman;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"3\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Provide accurate contact details in your online purchase order.</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-roman;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"3\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Ensure sufficient funds available on the subject credit card to pay the scheduled installment payments throughout the payment period.&nbsp;</span></span></li>\n" +
				"            <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-roman;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"3\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">Contact Bass Coast Project Ltd. regarding any dispute prior to the monthly payment due date</span><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">.</span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\">&nbsp; If a Participant believes a payment has been initiated incorrectly, they must email tickets{'@'}basscoast.ca immediately to address the issue.</span></span>\n" +
				"            </li>\n" +
				"        </ol>\n" +
				"    </li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"7\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Cancellation of Payment Plan:&nbsp;</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>&nbsp;</strong>Cancellation of a Payment Plan order must be made PRIOR to the final installment owing. Orders can only be cancelled while in the agreed-upon payment schedule.&nbsp; Once the final payment has been made, there is no cancellation option available to the Participant.&nbsp; Cancellations will not result in a refund but may be credited to the ticket purchaser’s online account for a future Event purchase.&nbsp;&nbsp;&nbsp;</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"8\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Credit of Cancelled Payment Plan:</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong> </strong>If a Payment Plan is cancelled WITHIN the agreed upon payment schedule (before all installments have been completed), Bass Coast Project Ltd. may issue a credit to the Participant’s online account for future Event purchases.&nbsp;</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>\n" +
				"<ol style=\"margin-bottom:0;margin-top:0;padding-inline-start:48px;\" start=\"9\">\n" +
				"    <li style=\"background-color:transparent;color:#FFFFFF;font-family:Arial,sans-serif;font-size:11pt;font-style:normal;font-variant:normal;list-style-type:lower-alpha;text-decoration:none;vertical-align:baseline;white-space:pre;\" dir=\"ltr\" aria-level=\"2\"><span style=\"background-color:transparent;color:#FFFFFF;font-family:Raleway,sans-serif;font-size:11pt;\"><span style=\"font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>Cancellation is Final</strong></span><span style=\"font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;\"><strong>: </strong>Once a cancellation of a Payment Plan occurs, the reserved tickets and/or reserved camping or bell tent order are released and will be offered for sale to other Participants.</span></span></li>\n" +
				"</ol>\n" +
				"<p>&nbsp;</p>"
		},
		fr: {},
	},
}
